.rtl-support {
  .phone-input {
    direction: ltr;
    text-align: right;
  }
}

.input-without-border-radius {
  border-radius: unset;
  margin-left: -1px;
}

.column-filter-input {
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.column-filter-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
