.lazySelect {
  font-size: 1rem;
}

.css-1pahdxg-control {
  background-color: transparent !important;
}
.disabled {
  background-color: #e9ecef !important;
  opacity: 1;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  div {
    background-color: transparent !important;
  }
  .css-lr9fot-singleValue {
    color: #495057 !important;
  }
}
.css-1pahdxg-control:active {
  color: #495057 !important;
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}
