.collapse__wrapper {

  @include directify($directions) {
    text-align: directed('left');
  }
  &.opened, &.opening {

    .collapse__title {

      p {
        color: $color-additional;
      }

      svg {
        fill: $color-additional;
      }
    }
  }

  &.boxed {
    border: solid 1px $color-dusty-white;
    margin-top: -1px;

    .collapse__title {
      background-color: $color-background-body;
      border-bottom: solid 1px $color-dusty-white;
      padding: 8px 15px;

      p {
        color: $color-gray;
      }

      svg {
        display: none;
      }
    }

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 20px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 16px;
      padding-bottom: 20px;
    }

    &.closed {

      .collapse__title {
        border-bottom: none;
      }
    }
  }

  &.with-shadow {
    margin-bottom: 10px;
    box-shadow: none;

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 20px;
        #{directed('padding-left')}: 25px;
      }
      padding-top: 15px;
      padding-bottom: 15px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-right: 25px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
      transition: background-color 0.3s;

      @include themify($themes) {
        color: themed('colorText');
      }

      &:hover {

        @include themify($themes) {
          background-color: themed('colorHover');
        }
      }

      svg {
        display: none;
      }

      p {

        svg {
          display: block;
          top: calc(50% - 7px);
          transition: all 0.3s;

          @include themify($themes) {
            fill: themed('colorText');
          }
        }
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {

      p {
        color: inherit;
      }

      svg {
        fill: inherit;
        transform: rotate(180deg);
      }
    }
  }

  &.closing {

    .collapse__content {
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.3s;
    }
  }
}

.collapse__title, .collapse__content {
  @include directify($directions) {
    #{directed('padding-left')}: 20px;
    #{directed('padding-right')}: 15px;
  }
}

.collapse__title {
  padding-bottom: 10px;
  padding-top: 6px;
  cursor: pointer;
  position: relative;
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  @include directify($directions) {
    text-align: directed('left');
  }

  p {
    font-weight: 700;
    margin-top: 0;
    transition: all 0.3s;
    position: relative;

    svg {
      @include directify($directions) {
        #{directed('right')}: -10px;
        #{directed('left')}: auto;
      }
      display: none;
    }
  }

  svg {
    position: absolute;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    width: 14px;
    height: 14px;
    top: 8px;
    transition: all 0.3s;

    @include themify($themes) {
      fill: themed('colorText');
    }

  }
}

.collapse__content {
  padding-bottom: 14px;
}